define("discourse/plugins/discourse-mailing-list-user-preferences/discourse/templates/components/category-checkbox-list", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each categories as |category|}}
      <label class="checkbox-label {{if category.parentCategory 'checkbox-label--indent' }}">
          <Input
              @type="checkbox"
              @checked={{category.checked}}
              {{on "click" (fn this.actions.onChange this category)}}
          />
          {{category.name}}
      </label>
  {{/each}}
  
  */
  {
    "id": "r4hqMa6O",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"categories\"]]],null]],null],null,[[[1,\"    \"],[10,\"label\"],[15,0,[29,[\"checkbox-label \",[52,[30,1,[\"parentCategory\"]],\"checkbox-label--indent\"]]]],[12],[1,\"\\n        \"],[8,[39,3],[[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"actions\",\"onChange\"]],[30,0],[30,1]],null]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,1,[\"checked\"]]]],null],[1,\"\\n        \"],[1,[30,1,[\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[1,[28,[32,0],[\"[[\\\"The `categories` property path was used in the `discourse/plugins/discourse-mailing-list-user-preferences/discourse/templates/components/category-checkbox-list.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.categories}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"category\"],false,[\"each\",\"-track-array\",\"if\",\"input\",\"on\",\"fn\"]]",
    "moduleName": "discourse/plugins/discourse-mailing-list-user-preferences/discourse/templates/components/category-checkbox-list.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});